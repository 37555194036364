import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { PUBLIC_USER_LOGIN, PUBLIC_USER_PASSWORD_RESET } from 'Consts/routes';
import { API_RESOURCE_IS_TOKEN_ACTIVE } from 'Consts/apiResources';

import { getRolePath } from 'Utils/user';

import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import PasswordChangeForm from 'Components/publicUser/PasswordChangeForm';
import Button from 'Components/layout/Button';

export default class PublicPasswordChange extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            passwordTokenVerify: PropTypes.func.isRequired,
        }).isRequired,
        authToken: PropTypes.string,
        profile: PropTypes.object,
    };
    static defaultProps = {
        authToken: null,
        profile: null,
    }
    state = {
        isSuccess: false,
    };

    onSuccess = () => this.setState({ isSuccess: true });

    componentDidMount = () => {
        const { actions, match, history, authToken } = this.props;

        if(authToken) {
            return this.redirectToRole();
        }

        actions.passwordTokenVerify({ token: match.params.token })
            .then((response) => {
                const isTokenActive = response.payload[API_RESOURCE_IS_TOKEN_ACTIVE];
                
                if (!isTokenActive) {
                    history.push(PUBLIC_USER_PASSWORD_RESET.path);
                    toast('Link jest nieaktywny, zresetuj hasło.');
                }
            });
    }

    componentDidUpdate = (prevProps) => {
        const { authToken } = this.props;

        if(authToken && JSON.stringify(authToken) !== JSON.stringify(prevProps.authToken)) {
            return this.redirectToRole();
        }
    }

    redirectToRole = () => {
        const { authToken, profile, history } = this.props;

        if (authToken && profile) {
            const rolePath = getRolePath(profile);
            if(rolePath?.path) {
                history.push(rolePath.path);
            }
        }
    }

    render() {
        const { match } = this.props;
        const { isSuccess } = this.state;

        return (
            <StyledComponent
                styles={require('./styles')}
                className="component-password-change-page"
            >
                <LayoutContainer>
                    <div className="section-container">
                        <div className="logo">
                            <img src={require('Theme/images/logo-color-black.png')} />
                        </div>
                        {isSuccess
                            ?
                            <>
                                <h2 className="section-title small">Nowe hasło zostało ustawione.</h2>
                                <Link to={PUBLIC_USER_LOGIN.path}>
                                    <Button
                                        style="gradient"
                                        size="large"
                                    >
                                        Przejdź do logowania
                                    </Button>
                                </Link>
                            </>
                            :
                            <>
                                <h2 className="section-title">Zmień hasło</h2>
                                <PasswordChangeForm token={match.params.token} onSuccess={this.onSuccess} />
                            </>
                        }
                    </div>
                </LayoutContainer>
            </StyledComponent>
        );
    }
}
