import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ERROR_MSG_CRITICAL } from 'Consts/errors';
import Logger from 'Services/Logger';

import StyledComponent from 'Components/core/StyledComponent';
import Form from 'Components/forms/Form';
import Button from 'Components/layout/Button';
import Input from 'Components/forms/Input';

export default class PasswordChangeFormForm extends Component {
    static propTypes = {
        onSuccess: PropTypes.func,
        actions: PropTypes.shape({
            passwordChange: PropTypes.func.isRequired,
        }).isRequired,
        token: PropTypes.string.isRequired,
    };
    static defaultProps = {
        onSuccess: null,
    };
    state = {
        globalError: null,
        errors: {},
        isPending: false,
        formState: {
            password: null,
            passwordConfirm: null,
        },
    };

    onSubmit = () => {
        const { formState } = this.state;
        const { actions, token } = this.props;
        
        this.setState({ isPending: true });

        return actions.passwordChange({
            token,
            password: formState.password,
            passwordConfirm: formState.passwordConfirm, 
        })
            .then(response => {
                this.onSuccess(response);
                this.setState({ isPending: false });
            })
            .catch(error => {
                Logger.error('[RESET_CHANGE_FORM] Critical Error', error);
                this.setState({
                    isPending: false,
                    errors: error?.payload?.validationErrors,
                    globalError: error?.payload?.message,
                });
            });
    }

    onSuccess = response => {
        const { onSuccess } = this.props;
        return onSuccess(response);
    }

    onStateChange = (name, value) => this.setState(prevState => ({
        formState: {
            ...prevState.formState,
            [name]: value,
        },
    }))

    render() {
        const { formState, globalError, isPending, errors } = this.state;

        return (
            <StyledComponent
                styles={require('./styles')}
                className="component-password-change-form"
            >
                <Form
                    data={formState}
                    onStateChange={this.onStateChange}
                    errors={errors}
                    onSubmit={this.onSubmit}
                    isPending={isPending}
                    globalError={globalError}
                >
                    <Input
                        label="Nowe hasło"
                        name="password"
                        placeholder="Wprowadź nowe hasło"
                        type="password"
                        styleVersion={2}
                    />
                    <Input
                        label="Powtórz hasło"
                        name="passwordConfirm"
                        placeholder="Powtórz hasło"
                        type="password"
                        styleVersion={2}
                    />
                    <Button
                        className="submit-button"
                        type="submit"
                        icon={{ type: 'fa', source: 'fas fa-arrow-right' }}
                        layout="fullWidth"
                        size="large"
                        style="gradient"
                    >
                        Zmień hasło
                    </Button>
                </Form>
            </StyledComponent>
        );
    }
}
