import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default () =>
    css({
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',

        '.submit-button': {
            margin: '.5em 0',
        },
    });
