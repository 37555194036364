import { connect } from 'react-redux';

import { passwordChange } from 'Redux/modules/user/actions';

import Component from './component';

export default connect(
    state => ({
        user: state.user,
    }), 
    dispatch => ({
        actions: {
            passwordChange: dispatch(passwordChange),
        },
    })
)(Component);

